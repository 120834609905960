import axios from 'axios';
import React, { useEffect } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';


function App() {
  useEffect(() => {
    // eslint-disable-next-line
    trackerManager()
  }, []);

  const trackerManager = async () => {
    try {
      let params = new URL(document.location.toString()).searchParams;
      const token = params.get("campaign")
      if (token) {
        const resp = await axios.get(`https://api.dropora.com/api/v1/campaigns/count/${token}`)
        console.log(resp.data)
      }
    } catch (e) {
      console.log(e)
    }
    if (isMobile) {
      if (isAndroid)
        window.location.href = "https://play.google.com/store/apps/details?id=com.qwrtx.dropora"
      else if (isIOS)
        window.location.href = "https://apps.apple.com/us/app/dropora/id6478829126?platform=iphone"
      else
        window.location.href = "https://dropora.com"
    } else
      window.location.href = "https://dropora.com"
  }

  return (
    <p>Redirecting to store... Please wait</p>
  );
}

export default App;
